<template>
	<div class="content">
		<div class="tips">为您的产品信息选择合适的行业</div>
		<div class="tabs">
			<div v-for="(item,index) in ['查找行业','您常用的行业']" :key="item" @click="handleTabClick(index)"
				:class="index==tabActive?'active':''">{{item}}</div>
		</div>
		<div class="release-top">
			<input type="text" class="search-input" placeholder="请输入产品关键词，如：臭氧发生器" v-model="searchVal">
			<button @click="handleSearch">查找行业</button>
			<p>或者</p>
			<span @click="handleManual">手动选择</span>
		</div>
		<template v-if="!tabActive">
			<el-cascader-panel ref="catePanel" v-model="cateValue" :options="options" :props="props" class="catePanel"
				@change="handleChange" v-if="!isSearch" />
			<template v-else>
				<div class="search-empty" v-if="!searchData.length">系统未找到匹配的分类，您可以自行<span
						@click="handleManual">手动选择</span></div>
				<ul class="used-wrap" v-else>
					<li v-for="(item,index) in searchData" :key="index">
						<el-radio v-model="cateValue" :label="item.id" @change="handleRadioChange(item.catname)">
							<template v-for="(items,indexs) in item.catname">
								{{items}}<i v-if="indexs!=item.catname.length-1" class="el-icon-arrow-right" />
							</template>
						</el-radio>
					</li>
				</ul>
			</template>
		</template>
		<ul class="used-wrap" v-else="tabActive">
			<li v-for="(item,index) in radioData" :key="index">
				<el-radio v-model="cateValue" :label="item.id" @change="handleRadioChange(item.catname)">
					<template v-for="(items,indexs) in item.catname">
						{{items}}<i v-if="indexs!=item.catname.length-1" class="el-icon-arrow-right" />
					</template>
				</el-radio>
			</li>
		</ul>
		<div class="cateTips" v-if="cateLabel.length">
			<span>您当前选择的行业是：</span>
			<template v-for="(item,index) in cateLabel">
				{{item}}<i class="el-icon-arrow-right" v-if="index!=cateLabel.length-1" />
			</template>
		</div>
		<button class="next-btn" :disabled="!cateValue.length" @click="handleNext">下一步，填写详细信息</button>
	</div>
</template>

<script>
	let id = 0;
	export default {
		data() {
			return {
				tabActive: 0,
				cateValue: [],
				cateLabel: [],
				options: [],
				radioData: [],
				searchData: [],
				props: {
					value: 'id',
					label: 'catname',
					children: 'childrens',
					lazy: true,
					lazyLoad: async (node, resolve) => {
						if (node.value) {
							this.getData(node.value).then(data => {
								const nodes = data.map(item => ({
									id: item.id,
									catname: item.catname,
									leaf: item.children == 0
								}));
								resolve(nodes)
							})
						}
					}
				},
				isSearch: false,
				searchVal: ''
			}
		},
		async created() {
			await this.getData().then(data => {
				this.options = data
			})
			this.$axios.post('/usedCategory').then(data => {
				this.radioData = this.setRadio(data)
			})
		},
		methods: {
			setRadio(data) {
				let list = []
				data.forEach((item, index) => {
					list.push({
						catname: [],
						id: []
					})
					item.forEach(items => {
						list[index].catname.push(items.catname)
						list[index].id.push(items.id)
					})
				})
				return list
			},
			handleTabClick(index) {
				this.tabActive = index
			},
			handleChange(e) {
				let nodes = this.$refs.catePanel.getCheckedNodes()[0]
				this.cateLabel = nodes.pathLabels
			},
			handleNext() {
				const data = {
					id: this.cateValue,
					name: this.cateLabel
				}
				this.$emit('eventCateList', data)
			},
			getData(catid = '') {
				return new Promise((resolve, reject) => {
					this.$axios.post('/getChildrenCategory', {
						catid
					}).then(data => {
						resolve(data)
					})
				})
			},
			handleManual() {
				this.isSearch = false
				this.tabActive = 0
			},
			handleRadioChange(catname) {
				this.cateLabel = catname
			},
			handleSearch() {
				if (this.searchVal == '') {
					this.$message.warning('请填写搜索内容')
					return
				}
				this.$axios.post('/searchCategory', {
					title: this.searchVal
				}).then(data => {
					this.isSearch = true
					this.tabActive = 0
					if (data.code == 400005) {
						this.searchData = []
					} else {
						this.searchData = this.setRadio(data)
					}
				})
			}
		},
		props: {
			cat_id: {
				type: Array,
				default: () => ([])
			}
		},
		computed: {
			init() {
				const {
					cat_id,
					options
				} = this
				return {
					cat_id,
					options
				}
			}
		},
		watch: {
			async init(val) {
				const {
					cat_id,
					options
				} = val
				let arr = [options]
				if (cat_id.length && options.length) {
					for (let i = 0; i < cat_id.length - 1; i++) {
						await this.getData(cat_id[i]).then(data => {
							arr.push(data)
						})
					}
					this.cateLabel = []
					arr.forEach((item, index) => {
						item.forEach(items => {
							if (items.id == cat_id[index]) {
								this.cateLabel.push(items.catname)
							}
						})
					})
					this.cateValue = cat_id
					this.$emit('eventCateList', {
						id: this.cateValue,
						name: this.cateLabel
					})
				}
			}
		}
	}
</script>

<style scoped>
	.tips {
		font-size: 14px;
		font-weight: 700;
		height: 18px;
		line-height: 18px;
		position: relative;
		padding-left: 25px;
		margin-top: 18px;
	}

	.tips::before {
		content: '';
		width: 25px;
		height: 18px;
		left: 0;
		position: absolute;
		background: url(../../assets/img/bulb.png) no-repeat center;
		background-size: 12px 18px;
	}

	.tabs {
		display: flex;
		border-bottom: 1PX solid #e6e6e6;
		margin-top: 17px;
		height: 40px;
	}

	.tabs div {
		border: 1PX solid #e6e6e6;
		border-bottom: none;
		width: 116px;
		height: 39px;
		font-size: 14px;
		color: #666666;
		text-align: center;
		line-height: 39px;
		margin-right: 14px;
		box-sizing: border-box;
		cursor: pointer;
		background: #f7f7f7;
	}

	.tabs div.active {
		border-top: 3px solid #0f69dc;
		height: 40px;
		transition: all .3s;
	}

	.release-top {
		margin-top: 15px;
		display: flex;
		align-items: center;
		font-size: 14px;
		color: #333333;
	}

	.search-input {
		border: 1PX solid #eaeaea;
		height: 28px;
		padding: 0 5px;
		width: 286px;
	}

	.release-top button {
		border: none;
		width: 78px;
		height: 30px;
		border-radius: 5px;
		background: #0f69dc;
		color: #fff;
		margin: 0 10px;
	}

	.release-top span {
		cursor: pointer;
		color: #0f69dc;
		margin-left: 10px;
	}

	.catePanel {
		border: none;
		margin-top: 16px;
		background: #eff2f3;
		color: #333333;
	}

	.catePanel>>>.el-cascader-menu {
		border: 1PX solid #e6e6e6;
		margin-right: 15px;
		background: #fff;
	}

	.catePanel>>>.el-cascader-menu__wrap {
		height: 220px;
	}

	.cateTips {
		height: 42px;
		background: #fff9ed;
		display: flex;
		align-items: center;
		padding: 0 34px;
		font-size: 12px;
		color: #ffb30f;
		margin-top: 20px;
	}

	.cateTips span {
		color: #333333;
	}

	.next-btn {
		width: 194px;
		height: 40px;
		display: block;
		margin: 44px auto 0;
		font-size: 16px;
		background: #67c23a;
		color: #fff;
		border-radius: 5px;
		cursor: pointer;
		border: none;
		box-sizing: border-box;
	}

	.next-btn:disabled {
		border: 1px solid #e6e6e6;
		background: #f3f3f3;
		color: #999999;
	}

	.used-wrap {
		margin-top: 16px;
		border: 1PX solid #E4E7ED;
		padding: 10px 0;
		max-height: 400px;
		overflow-y: auto;
	}

	.used-wrap li {}

	.used-wrap .el-radio {
		display: block;
		padding: 10px 20px;
	}

	.used-wrap .is-checked {
		background: #eff2f3;
	}

	.search-empty {
		height: 150px;
		line-height: 150px;
		font-size: 14px;
	}

	.search-empty span {
		cursor: pointer;
		color: #0080FF;
	}
</style>
